/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
import Tagify from "@yaireo/tagify";
import '@yaireo/tagify/dist/tagify.css';

window.Tagify = Tagify;

// resources/app.js
//window.$ = require('jquery')dd
window.JQuery = require('jquery')
